(function($) {
  var pageTitle = document.querySelector('.page-title');
  //Page title underline animation
  var lastWord = pageTitle.innerText.split(' ').pop();
  pageTitle.innerText = pageTitle.innerText.replace(lastWord, '');
  var lastWordEl = document.createElement('span');
  lastWordEl.classList.add('ul-target');
  lastWordEl.innerText = lastWord;
  pageTitle.append(lastWordEl);
  //Check for trailing period - page title might change in the future
  if (lastWord.indexOf('.') !== -1) {
    lastWordEl.innerText = lastWordEl.innerText.replace('.', '');
    pageTitle.append(document.createTextNode('.'));
  }
  setTimeout(function() {
    lastWordEl.classList.add('active');
  }, 500);
})(jQuery);
